<template>
  <div class="main-panel">
    <h1>Tickets</h1>
    <div class="flexpanel">
      <input type="search" placeholder="search..." class="search" spellcheck="false" v-model="search" />
      <img class="action-btn" src="../assets/plus.svg" alt="" @click="$router.push({ name: 'Ticket' })" />
    </div>
    <div class="table">
      <table>
        <tr class="nohover">
          <th @click="changeSort('ticketnumber')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'ticketnumber'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Ticket
            </div>
          </th>
          <th @click="changeSort('datecreated')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'datecreated'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Created
            </div>
          </th>
          <th @click="changeSort('clientname')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'clientname'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Client
            </div>
          </th>
          <th @click="changeSort('description')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'description'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Description
            </div>
          </th>
          <th @click="changeSort('status')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'status'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Status
            </div>
          </th>
          <th @click="changeSort('dateclosed')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'dateclosed'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Date Closed
            </div>
          </th>
          <th @click="changeSort('createdby')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'createdby'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Created By
            </div>
          </th>
        </tr>
        <tr
          v-for="ticket in sortedTickets"
          :key="ticket.ticketid"
          @click="$router.push({ name: 'Ticket', params: { id: ticket.ticketid } })"
        >
          <td :class="{ id: true, red: ticket.priority == 3, yellow: ticket.priority == 2 }">
            #{{ ticket.ticketnumber }}
          </td>
          <td>{{ formatDate(ticket.datecreated) }}</td>
          <td>{{ ticket.clientname }}</td>
          <td>{{ ticket.description }}</td>
          <td>
            {{ ticket.status == '0' ? 'Open' : '' }}
            {{ ticket.status == '1' ? 'Closed' : '' }}
          </td>
          <td>{{ formatDate(ticket.dateclosed) }}</td>
          <td>{{ ticket.createdby }}</td>
        </tr>
        <tr v-if="sortedTickets.length == 0" class="nohover">
          <td colspan="7">No Tickets Found</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import tickets from '../composables/tickets';

export default {
  setup() {
    tickets.getTickets();
    const search = ref('');

    watch(search, () => {
      tickets.updateSearch(search.value);
    });

    return { sortedTickets: tickets.sortedTickets, search, state: tickets.state, changeSort: tickets.changeSort };
  },
  methods: {
    formatDate(dateNumber) {
      if (dateNumber == null) {
        return '';
      }
      if (dateNumber.toString().length != 13) {
        return dateNumber;
      }
      dateNumber = parseInt(dateNumber);
      const date = new Date(dateNumber);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.flexpanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table {
  padding-top: 25px;
  table {
    width: 100%;
    border-collapse: collapse;
    tr:hover {
      background: #575757;
      cursor: pointer;
    }
    tr.nohover:hover {
      background: #3a3a3a;
      cursor: default;
    }
    th {
      border-bottom: 1px solid #dfdfdf;
      text-align: left;

      div {
        display: inline-block;
        text-align: left;
        font-size: 0.6em;
        padding-left: 5px;
        text-align: center;
        img {
          width: 9px;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
    }
    td {
      padding: 6px 8px;
    }
    .red {
      color: #f66565;
    }
    .yellow {
      color: #fff8b5;
    }
  }
}
</style>
